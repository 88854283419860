exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.45.47.0\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".UspBarContentBlock__ScreenReaderTexts_hidden{clip:rect(0,0,0,0);position:absolute}.UspBarContentBlock__ScreenReaderTexts_remove{display:none}", ""]);

// exports
exports.locals = {
	"hidden": "UspBarContentBlock__ScreenReaderTexts_hidden",
	"remove": "UspBarContentBlock__ScreenReaderTexts_remove"
};