import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ActionButton.module.scss';
import { UseSanaTexts } from 'sana/texts';

const ActionButton = ({ isFocused, hasPaused, onFocus, onBlur, onClick }) => {
  return (
    <UseSanaTexts
      textKeys={['ButtonDescriptionPlay', 'ButtonDescriptionStop']}
    >{([ButtonDescriptionPlay, ButtonDescriptionStop]) => (
      <button
        className={classNames(!isFocused && !hasPaused && styles.hidden, styles.action, styles.rotation,
          hasPaused ? styles.play : styles.pause)}
        aria-label={hasPaused ? ButtonDescriptionPlay : ButtonDescriptionStop}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      >
        <svg width="42" height="22" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <rect className={styles.background} x="2" y="2" rx="5" ry="5" width="38" height="18" />
          <rect className={styles.border} x="4" y="4" rx="5" ry="5" width="34" height="14" />
          <polygon className={styles.pause} points="17 8 17 14" />
          <polygon className={styles.pause} points="24 8 24 14" />
          <polygon className={styles.play} points="17 8 17 14 25 11" />
        </svg>
      </button>
    )}
    </UseSanaTexts>
  );
};

ActionButton.propTypes = {
  isFocused: PropTypes.bool,
  hasPaused: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
};

export default ActionButton;